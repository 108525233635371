<template>
  <div class='examine_detail' v-loading="loading">
    <back />

    <div class='content globle_border'>
      <el-form ref="form1" class="form" :model="form" label-position="left" label-width="170px" disabled>
        <div class="title1">供应商信息</div>
        <el-form-item label="供应商名称">
          <el-input v-model="form.supplierName"></el-input>
        </el-form-item>
        <el-form-item label="LOGO">
          <el-image class="logo" fit="cover" :src="form.logoPic" :preview-src-list="[form.logoPic]"></el-image>
        </el-form-item>
        <el-form-item label="供应商简介">
          <el-input type="textarea" v-model="form.supplierIntroduce" :autosize="{ minRows: 5, maxRows: 15 }"></el-input>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="form.contacts"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="form.telphone"></el-input>
        </el-form-item>
        <div class="title2">营业资质</div>
        <el-form-item label="营业执照">
          <el-image class="busilicense" fit="cover" :src="form.busiLicense" :preview-src-list="[form.busiLicense]"></el-image>
        </el-form-item>
        <el-form-item label="统一社会信用代码">
          <el-input v-model="form.creditCode"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div class='times globle_border' v-if="form.status > 0">
      <el-form ref="form2" class="form" :model="form" label-position="left" :inline="true" disabled>
        <div class="exinfo">
          <el-form-item label="注册时间">
            <el-input v-model="form.createdTime"></el-input>
          </el-form-item>
          <el-form-item label="审核时间">
            <el-input v-model="form.updatedTime"></el-input>
          </el-form-item>
          <el-form-item label="审核状态">
            <div class="status" :class="colorStatus[form.status]">{{ mapStatus[form.status] }}</div>
          </el-form-item>
        </div>
        <div class="lose_content" v-if="form.status == 2">
          <div class="label">不通过原因</div>
          <el-input type="textarea" v-model="form.loseContent" :autosize="{ minRows: 5, maxRows: 15 }"></el-input>
        </div>
      </el-form>
    </div>

    <div class="btn" v-if="form.status == 0">
      <el-button type="danger" plain @click="handleApprove(2)">不通过</el-button>
      <el-button type="primary" @click="handleApprove(1)">通过</el-button>
    </div>

    <el-dialog title="审核不通过" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form ref="examineForm" class="examineForm" :rules="rules" :model="examineForm" label-position="left" label-width="130px">
        <el-form-item label="不通过原因" prop="loseContent">
          <el-input type="textarea" v-model="examineForm.loseContent" :autosize="{ minRows: 4, maxRows: 15 }"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('examineForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data () {
    return {
      id: null,
      loading: false,
      submitLoding: false,
      dialogVisible: false,
      colorStatus: ['red', 'green', 'gray'],
      mapStatus: ['待审核', '已通过', '不通过'],
      form: {
        supplierName: '',
        contacts: '',
        logoPic: '',
        telphone: '',
        supplierIntroduce: '',
        busiLicense: '',
        creditCode: '',
      },
      examineForm: {
        loseContent: '',
      },
      rules: {
        loseContent: [{ required: true, message: "请输入不通过原因", trigger: "submit" },],
      },
    }
  },
  created () {
    this.id = this.$route.query.id
    this.getSupplierDetail()
  },
  methods: {
    getSupplierDetail () {
      this.loading = true
      this.$axios.get(`${this.$api.supplierInfoDetails}/${this.id}`).then((res) => {
        this.form = res.data.result
        this.loading = false
      }).catch(() => {
        this.loading = false
      });
    },
    // 审核通过/不通过
    handleApprove (val) {
      if (val == 1) {
        this.$confirm(`是否确认审核通过?`, "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }).then(() => {
          this.$axios.put(this.$api.supplierInfoExamine, {
            supplierId: this.id,
            status: "1",
          }).then((res) => {
            if (res.data.code == 100) {
              this.$message.success(res.data.desc);
            }
            this.$router.go(-1)
          }).catch((err) => {
            this.$message.error(err.data.desc);
          });
        }).catch(() => {
        });
      } else {
        this.dialogVisible = true
      }
    },
    onCancel () {
      this.dialogVisible = false
    },
    // 提交表单
    submitForm (formName) {
      this.submitLoding = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.put(this.$api.supplierInfoExamine, {
            supplierId: this.id,
            loseContent: this.examineForm.loseContent,
            status: "2",
          }).then((res) => {
            this.$message.success(res.data.desc);
            this.getSupplierDetail();
            this.submitLoding = false
            this.dialogVisible = false;
            this.$router.go(-1)
          }).catch((err) => {
            this.$message.error(err.data.desc);
          });
        } else {
          this.submitLoding = false
          this.$message.error("提交失败");
          return false;
        }
      });
    },
  },
}
</script>

<style scoped lang='scss'>
.examine_detail {
  .content {
    padding: 60px 190px;
  }

  .form {

    .title1,
    .title2 {
      position: relative;
      font-weight: bold;
      margin-bottom: 40px;
    }

    .title1::before,
    .title2::before {
      content: '';
      position: absolute;
      top: 0;
      left: -12px;
      width: 4px;
      height: 22px;
      background-color: #069BBC;
    }

    .title2 {
      margin-top: 60px;
    }

    ::v-deep .el-form-item__label {
      color: #272727;
      font-size: 16px;
      padding-right: 40px;
    }

    ::v-deep .el-input__inner,
    ::v-deep .el-textarea__inner {
      background: #fff;
      border-radius: 10px;
      border: 1px solid #E5E7E6;
    }

    ::v-deep .el-input.is-disabled .el-input__inner,
    ::v-deep .el-textarea.is-disabled .el-textarea__inner {
      color: #1A1A1A;
    }

    .logo {
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 4px;
    }

    .busilicense {
      display: block;
      width: 133px;
      height: 100px;
      border-radius: 4px;
    }
  }

  .times {
    padding: 30px;
    margin-top: 30px;
    box-sizing: border-box;

    .exinfo {
      display: flex;
      justify-content: space-between;
      align-items: center;

      ::v-deep .el-form-item__label {
        padding-right: 16px;
      }

      ::v-deep .el-form-item {
        margin: 0;
      }
    }

    .lose_content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;

      .label {
        width: 90px;
        padding-right: 16px;
      }

      .label,
      ::v-deep .el-textarea__inner {
        color: #EB2500 !important;
      }

      ::v-deep .el-form-item__label {
        padding-right: 16px;
      }

      ::v-deep .el-form-item {
        display: block;
        margin: 0;
      }
    }

    .status {
      width: 202px;
      height: 40px;
      padding: 0 15px;
      border: 1px solid #E5E7E6;
      border-radius: 10px;
      box-sizing: border-box;
    }

    .red {
      color: #EB2500;
    }

    .green {
      color: #069BBC;
    }

    .gray {
      color: #68706E;
    }
  }

  .btn {
    text-align: center;
    margin: 30px 0;

    .el-button {
      width: 150px;
      margin: 0 20px;
    }
  }



}
</style>
